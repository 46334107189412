import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { useRequest } from 'src/providers';
import getInitials from '../../utils/getInitials';
import { deepPurple } from '@material-ui/core/colors';
import { FileCopy,  Print, Save, } from '@material-ui/icons';
import { Button, ButtonGroup, Tooltip, Chip } from '@mui/material';
import { useNavigate } from 'react-router';
import { Edit } from '@mui/icons-material';
import { orange } from '@mui/material/colors';

const CustomerListResults = ({ ...rest }) => {

  const navigator = useNavigate();

  const [state, setState] = useState({
    customers: []
  });

  const [getCustomers, _, isLoadingCustomers, errorGettingCustomers] = useRequest();

  useEffect(() => {

    getCustomers({
      method: 'GET',
      url: '/v1/customerList'
    }).then((response) => {

      if (response.data && response.data.data) {

        if(response.data.data.length === 0){
          const items = [{
            createdAt: new Date(),
            customerName: 'None',
            customerEmail: 'No Email found',
            customerContactNumber: '0000000000',
            customerAddressCity: 'None',
            uuid: 1,
          }];

        }

        setState((prevState) => ({
          ...prevState,
          customers: response.data.data
        }));
      }
    });

  }, []);

  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = state.customers.map((customer) => customer.uuid);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const actions = [
    { icon: <Edit/>, name: 'Edit Customer', onClick: (customerId) => navigator(`/app/customer/${customerId}`) },
    { icon: <FileCopy />, name: 'View All Invoices', onClick: (customerId) => navigator(`customer`) },
    // { icon: <Print />, name: 'View Outstanding Invoices', onClick: (customerId) => navigator(`/invoices/outstanding/${customerId}`) },
  ];

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === state.customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < state.customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Contact Number
                </TableCell>
                <TableCell>
                  City
                </TableCell>
                <TableCell>
                  Registration date
                </TableCell>
                <TableCell>
                  Account Information
                </TableCell>
                <TableCell align={'center'}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.customers.slice(page * limit, page * limit + limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.uuid}
                  selected={selectedCustomerIds.indexOf(customer.uuid) !== -1}>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.uuid) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.uuid)}
                      value="true"
                    />
                  </TableCell> */}
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}>
                      <Avatar
                        sx={{ bgcolor: deepPurple[500], mr: 2 }}>
                        {getInitials(customer.customerName)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1">
                        {customer.customerName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {customer.customerEmail}
                  </TableCell>
                  <TableCell>
                    {customer.customerContactNumber}
                  </TableCell>
                  <TableCell>
                    {customer.customerAddressCity}
                  </TableCell>
                  <TableCell>
                    {moment(customer.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Chip label={'Outstanding'} color={'error'}/>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                          m: 0.05,
                        },
                      }}
                    >
                      <ButtonGroup size={'small'}>
                        {actions.map((action) => (
                          <Tooltip title={action.name}>
                            <Button color={action.color || 'primary'} key={action.name} onClick={() => action.onClick(customer.uuid)}>
                              {action.icon}

                            </Button>
                          </Tooltip>
                        ))}
                      </ButtonGroup>

                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={state.customers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerListResults;
