import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import CustomerListResults from '../components/customer/CustomerListResults';
import { CustomerListToolbar } from '../components/customer/CustomerListToolbar';
import customers from '../__mocks__/customers';
import { getScreenSize } from 'src/utils/responsiveHook';

export const CustomerList = () => {

  const screenSize = getScreenSize();

  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >

        <Container maxWidth={false}>
          <CustomerListToolbar />
          <Box sx={{ pt: 3 }}>
            <CustomerListResults customers={customers} />
          </Box>
        </Container>

      </Box>
    </>
  );

}