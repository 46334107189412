import { Helmet } from 'react-helmet';
import { useRequest } from '../providers';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';

export const SettingsView = (props) => {

  const [state, setState] = useState({
    businessName: '',
    businessAddressLine1: '',
    businessAddressLine2: '',
    businessAddressCity: '',
    businessAddressPostalCode: '',
    taxNumber: '',
    contactNumber: '',
    includeTaxDefault: true,
    taxPercent: '',
    companyNumber: '',
    bankName: '',
    accountName: '',
    accountType: '',
    accountNumber: '',
    branchCode: '',
    additionalBankInfo: ''
  });

  const [getBusiness, businessData, isLoadingBusiness, errorGettingBusiness] = useRequest();
  const [updateBusiness, updatedBusinessData, loadingUpdatedBusiness, errorUpdatingBusiness] = useRequest();

  useEffect(() => {
    getBusiness({
      method: 'GET',
      url: '/v1/business'
    }).then((response) => {

      if (response.data && response.data.data) {
        setState((prevState) => ({
          ...prevState,
          ...response.data.data
        }));
      }
    });
  }, []);

  const updateBusinessInfo = () => {

    updateBusiness({
      method: 'PUT',
      url: '/v1/business',
      data: state
    }).then((response) => {

      if (response.data && response.data.data) {
        setState((prevState) => ({
          ...prevState,
          ...response.data.data
        }));
      }
    });

    return;
  }

  return (
    <>
      {(isLoadingBusiness)

        ? <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={isLoadingBusiness || loadingUpdatedBusiness}>
          <CircularProgress color={'primary'} />
        </Backdrop>

        :
        <>
          <Helmet>
            <title>Settings</title>
          </Helmet>
          <Box
            sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              py: 3
            }}
          >
            <Container maxWidth='lg'>
              <Card>
                <CardHeader
                  subheader='Manage your business settings'
                  title='Business Settings' />

                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={6}
                    wrap='wrap'>

                    <Grid
                      item
                      md={4}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      xs={12}>
                      <Typography
                        color='textPrimary'
                        gutterBottom
                        variant='h6'
                      >
                        Business Details
                      </Typography>

                      <TextField
                        required={true}
                        size={'small'}
                        label='Business Name'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            businessName: data.target.value
                          }));
                        }}
                        value={state.businessName}
                        variant='outlined' />

                      <TextField
                        required={false}
                        size={'small'}
                        label='Business Registration Number'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            companyNumber: data.target.value
                          }));
                        }}
                        value={state.companyNumber}
                        variant='outlined' />

                      <TextField
                        required={true}
                        size={'small'}
                        label='Contact Number'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            contactNumber: data.target.value
                          }));
                        }}
                        value={state.contactNumber}
                        variant='outlined' />

                    </Grid>

                    <Grid
                      item
                      md={4}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      xs={12}
                    >
                      <Typography
                        color='textPrimary'
                        gutterBottom
                        variant='h6'
                      >
                        Address
                      </Typography>

                      <TextField
                        required={true}
                        size={'small'}
                        label='Street Address'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            businessAddressLine1: data.target.value
                          }));
                        }}
                        value={state.businessAddressLine1}
                        variant='outlined' />

                      <TextField
                        required={true}
                        size={'small'}
                        label='Extension'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            businessAddressLine2: data.target.value
                          }));
                        }}
                        value={state.businessAddressLine2}
                        variant='outlined' />

                      <TextField
                        required={true}
                        size={'small'}
                        label='City'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            businessAddressCity: data.target.value
                          }));
                        }}
                        value={state.businessAddressCity}
                        variant='outlined' />

                      <TextField
                        required={true}
                        size={'small'}
                        label='Postal Code'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            businessAddressPostalCode: data.target.value
                          }));
                        }}
                        value={state.businessAddressPostalCode}
                        variant='outlined' />

                    </Grid>

                    <Grid
                      item
                      md={4}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      xs={12}>

                      <Typography
                        color='textPrimary'
                        gutterBottom
                        variant='h6'>

                        Tax
                      </Typography>

                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            onChange={(data) => {
                              setState((prevState) => ({
                                ...prevState,
                                includeTaxDefault: data.target.checked
                              }));
                            }}
                            checked={state.includeTaxDefault} />
                        )}
                        label='Include Tax' />

                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        xs={6}>

                        <TextField
                          required={state.includeTaxDefault}
                          disabled={!state.includeTaxDefault}
                          size={'small'}
                          label='Tax Percentage'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              taxPercent: data.target.value
                            }));
                          }}
                          value={state.taxPercent}
                          helperText={'Value in percentage.'}
                          variant='outlined' />
                      </Grid>

                      <TextField
                        required={false}
                        size={'small'}
                        label='Tax Number'
                        margin='normal'
                        onChange={(data) => {
                          setState((prevState) => ({
                            ...prevState,
                            taxNumber: data.target.value
                          }));
                        }}
                        value={state.taxNumber}
                        variant='outlined' />

                    </Grid>

                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={updateBusinessInfo}>
                    Save
                  </Button>
                </Box>
              </Card>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <CardHeader
                    subheader='These details will be included on your documents. Please ensure it is correct.'
                    title='Banking information' />

                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={6}
                      wrap='wrap'>
                      <Grid
                        item
                        md={4}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        xs={12}
                      >
                        <Typography
                          color='textPrimary'
                          gutterBottom
                          variant='h6'
                        >
                          Business Bank Details
                        </Typography>

                        <TextField
                          required={true}
                          size={'small'}
                          label='Bank Name'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              bankName: data.target.value
                            }));
                          }}
                          value={state.bankName}
                          variant='outlined' />

                        <TextField
                          required={true}
                          size={'small'}
                          label='Account Type'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              accountType: data.target.value
                            }));
                          }}
                          value={state.accountType}
                          variant='outlined' />

                        <TextField
                          required={true}
                          size={'small'}
                          label='Account Number'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              accountNumber: data.target.value
                            }));
                          }}
                          value={state.accountNumber}
                          variant='outlined' />

                      </Grid>

                      <Grid
                        item
                        md={4}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        xs={12}
                      >
                        <Typography
                          color='textPrimary'
                          gutterBottom
                          variant='h6'>
                          Additional Details
                        </Typography>

                        <TextField
                          required={true}
                          size={'small'}
                          label='Branch Code'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              branchCode: data.target.value
                            }));
                          }}
                          value={state.branchCode}
                          variant='outlined' />

                        <TextField
                          required={false}
                          size={'small'}
                          label='Additional Text'
                          helperText={'This text will be included below your banking details on all documents.'}
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              additionalBankInfo: data.target.value
                            }));
                          }}
                          value={state.additionalBankInfo}
                          variant='outlined' />

                      </Grid>

                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={updateBusinessInfo}>
                      Save
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Container>
            <Backdrop
              sx={{ color: '#fff', zIndex: 1 }}
              open={loadingUpdatedBusiness}>
              <CircularProgress color={'primary'} />
            </Backdrop>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={updatedBusinessData}
              onClose={() => { }}
              autoHideDuration={6000}>
              <Alert severity='success' sx={{ width: '100%' }}>
                Settings Successfully updated!
              </Alert>
            </Snackbar>
          </Box>
        </>
      }
    </>
  );

}
