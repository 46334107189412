import { Helmet } from 'react-helmet';
import { useRequest } from '../providers';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const CustomerView = (props) => {

  const [state, setState] = useState({
    customerName: '',
    customerAddressLine1: '',
    customerAddressLine2: '',
    customerAddressCity: '',
    customerAddressPostalCode: '',
    customerContactNumber: '',
    customerEmail: '',
    customerTaxNumber: '',
    customerBusinessRegistrationNumber: '',
    customerBankBranchCode: '',
    customerBankAccountNumber: '',
    customerBankAccountType: '',
    customerBankName: '',
  });

  const { id } = useParams();

  const [getCustomer, customerData, isLoadingCustomer, errorGettingCustomer] = useRequest();
  const [updateCustomer, updatedCustomerData, loadingUpdatedCustomer, errorUpdatingCustomer] = useRequest();

  useEffect(() => {

    if (id) {
      getCustomer({
        method: 'GET',
        url: `/v1/customer/${id}`
      }).then((response) => {

        if (response.data && response.data.data) {
          setState((prevState) => ({
            ...prevState,
            ...response.data.data
          }));
        }
      });
    }
  }, []);

  const createCustomer = () => {

    if(id){
      updateCustomer({
        method: 'PUT',
        url: '/v1/customer',
        data: state
      }).then((response) => {
  
        if (response.data && response.data.data) {
          setState((prevState) => ({
            ...prevState,
            ...response.data.data
          }));
        }
      });

      return;
    }


    updateCustomer({
      method: 'POST',
      url: '/v1/customer',
      data: state
    }).then((response) => {

      if (response.data && response.data.data) {
        setState((prevState) => ({
          ...prevState,
          ...response.data.data
        }));
      }
    });

    return;
  }

  return (
    <>
      {(isLoadingCustomer)

        ? <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={isLoadingCustomer || loadingUpdatedCustomer}>
          <CircularProgress color={'primary'} />
        </Backdrop>

        :
        <>
          <Helmet>
            <title>Customer</title>
          </Helmet>
          <Box
            sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              py: 3
            }}
          >
            <Container maxWidth='lg'>
              <Container maxWidth='lg'>
                <Card>
                  <CardHeader
                    subheader='Manage your customers'
                    title='Customer Details' />

                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={6}
                      wrap='wrap'>

                      <Grid
                        item
                        md={4}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        xs={12}>
                        <Typography
                          color='textPrimary'
                          gutterBottom
                          variant='h6'
                        >
                          Customer Details
                        </Typography>

                        <TextField
                          required={true}
                          size={'small'}
                          label='Customer Name'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerName: data.target.value
                            }));
                          }}
                          value={state.customerName}
                          variant='outlined' />

                        <TextField
                          required={true}
                          size={'small'}
                          label='Email Address'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerEmail: data.target.value
                            }));
                          }}
                          value={state.customerEmail}
                          variant='outlined' />

                        <TextField
                          required={true}
                          size={'small'}
                          label='Contact Number'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerContactNumber: data.target.value
                            }));
                          }}
                          value={state.customerContactNumber}
                          variant='outlined' />

                      </Grid>

                      <Grid
                        item
                        md={4}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        xs={12}
                      >
                        <Typography
                          color='textPrimary'
                          gutterBottom
                          variant='h6'
                        >
                          Address
                        </Typography>

                        <TextField
                          required={true}
                          size={'small'}
                          label='Street Address'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerAddressLine1: data.target.value
                            }));
                          }}
                          value={state.customerAddressLine1}
                          variant='outlined' />

                        <TextField
                          required={true}
                          size={'small'}
                          label='Extension'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerAddressLine2: data.target.value
                            }));
                          }}
                          value={state.customerAddressLine2}
                          variant='outlined' />

                        <TextField
                          required={true}
                          size={'small'}
                          label='City'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerAddressCity: data.target.value
                            }));
                          }}
                          value={state.customerAddressCity}
                          variant='outlined' />

                        <TextField
                          required={true}
                          size={'small'}
                          label='Postal Code'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerAddressPostalCode: data.target.value
                            }));
                          }}
                          value={state.customerAddressPostalCode}
                          variant='outlined' />

                      </Grid>

                      <Grid
                        item
                        md={4}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        xs={12}>

                        <Typography
                          color='textPrimary'
                          gutterBottom
                          variant='h6'>
                          Customer Business Information
                        </Typography>

                        <TextField
                          required={false}
                          size={'small'}
                          label='Tax Number'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerTaxNumber: data.target.value
                            }));
                          }}
                          value={state.customerTaxNumber}
                          variant='outlined' />

                        <TextField
                          required={false}
                          size={'small'}
                          label='Customer Business Registration Number'
                          margin='normal'
                          onChange={(data) => {
                            setState((prevState) => ({
                              ...prevState,
                              customerBusinessRegistrationNumber: data.target.value
                            }));
                          }}
                          value={state.customerBusinessRegistrationNumber}
                          variant='outlined' />

                      </Grid>

                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={createCustomer}>
                      Save
                    </Button>
                  </Box>
                </Card>
                <Box sx={{ pt: 3 }}>
                  <Card>
                    <CardHeader
                      subheader='These details are in reference to your customer. Please ensure it is correct.'
                      title='Banking information' />

                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        spacing={6}
                        wrap='wrap'>
                        <Grid
                          item
                          md={4}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                          xs={12}
                        >
                          <Typography
                            color='textPrimary'
                            gutterBottom
                            variant='h6'
                          >
                            Customer Bank Details
                          </Typography>

                          <TextField
                            required={true}
                            size={'small'}
                            label='Bank Name'
                            margin='normal'
                            onChange={(data) => {
                              setState((prevState) => ({
                                ...prevState,
                                customerBankName: data.target.value
                              }));
                            }}
                            value={state.customerBankName}
                            variant='outlined' />

                          <TextField
                            required={true}
                            size={'small'}
                            label='Account Type'
                            margin='normal'
                            onChange={(data) => {
                              setState((prevState) => ({
                                ...prevState,
                                customerBankAccountType: data.target.value
                              }));
                            }}
                            value={state.customerBankAccountType}
                            variant='outlined' />

                          <TextField
                            required={true}
                            size={'small'}
                            label='Account Number'
                            margin='normal'
                            onChange={(data) => {
                              setState((prevState) => ({
                                ...prevState,
                                customerBankAccountNumber: data.target.value
                              }));
                            }}
                            value={state.customerBankAccountNumber}
                            variant='outlined' />

                        </Grid>

                        <Grid
                          item
                          md={4}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                          xs={12}
                        >
                          <Typography
                            color='textPrimary'
                            gutterBottom
                            variant='h6'>
                            Additional Details
                          </Typography>

                          <TextField
                            required={true}
                            size={'small'}
                            label='Branch Code'
                            margin='normal'
                            onChange={(data) => {
                              setState((prevState) => ({
                                ...prevState,
                                customerBankBranchCode: data.target.value
                              }));
                            }}
                            value={state.customerBankBranchCode}
                            variant='outlined' />

                        </Grid>

                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                      }}
                    >
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={createCustomer}>
                        Save
                      </Button>
                    </Box>
                  </Card>
                </Box>
              </Container>
              <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={loadingUpdatedCustomer}>
                <CircularProgress color={'primary'} />
              </Backdrop>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={updatedCustomerData}
                onClose={() => { }}
                autoHideDuration={60}>
                <Alert severity='success' sx={{ width: '100%' }}>
                  Settings Successfully updated!
                </Alert>
              </Snackbar>
            </Container>
          </Box>
        </>
      }
    </>
  );

}
