import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import { CustomerList } from './pages/CustomerList';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import { Register } from './pages/Register';
import { SettingsView } from './pages/Settings';
import { authHeader } from './providers/auth';
import { InvoiceList } from './pages/InvoiceList';
import { CustomerView } from './pages/Customer';

const routes = (isAuthed) => [
  {
    path: 'app',
    element: isAuthed ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'customer/:id', element: <CustomerView /> },
      { path: 'customer', element: <CustomerView /> },
      { path: 'invoices', element: <InvoiceList /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  },
  {
    path: '/',
    element: !isAuthed ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: authHeader() ? <Navigate to="/app/dashboard" /> : <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: authHeader() ? <Navigate to="/app/dashboard" /> : <Navigate to="/404" /> }
    ]
  }
];

export default routes;
