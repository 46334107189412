import axios from 'axios';

const API_URL = `${process.env.REACT_APP_PROXY}/api/auth/`;

export const login = (username, password) => axios
  .post(`${API_URL}signin`, {
    username,
    password
  })
  .then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem('token', JSON.stringify(response.data.accessToken));
    }

    return response.data;
  });

export const logout = () => {
  localStorage.removeItem('token');
};

export const register = (username, email, password) => axios.post(`${API_URL}signup`, {
  username,
  email,
  password
});

export const getCurrentUser = () => JSON.parse(localStorage.getItem('token'));

export const authHeader = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (token) {
    return { 'x-api-token': token};
  }
  return false;
};
