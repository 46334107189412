import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';

const moment = require('moment');

export const InvoiceCard = (props) => {
  const theme = useTheme();

  const handleStatus = (status) => {

    switch (status) {
      case 'draft':
        return <Chip variant={'outlined'} label={'Draft'} color={'error'} />
      case 'invoiced':
        return <Chip label={'Awaiting Payment'} color={'warn'} />
      case 'paid':
        return <Chip label={'Paid'} color={'success'} />
      default:
        return <Chip label={'Draft'} color={'error'} />
        break;
    }
  }

  return (
    <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {props.customerName}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Invoice Number: {props.invoiceNumber}
          </Typography>
          {handleStatus(props.status)}
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5" alignContent={'end'}>
            R {props.grandTotal}
          </Typography>
          <Typography variant="subtitle1" color={moment().isAfter(moment(props.dueDate)) ? 'red' : "text.secondary"} component="div">
            Due Date: {props.dueDate}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton>
              <CreateIcon />
            </IconButton>
            <IconButton>
              <VisibilityIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
}