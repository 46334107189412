import { useState } from 'react';
import axios from 'axios';
import { authHeader } from './auth';
import { useNavigate } from 'react-router';

const REACT_APP_PROXY = process.env.REACT_APP_PROXY

export function useRequest(initial = null) {
  const [data, setData] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * To determine the success of a request, use the res.successful
   * property that is set in a response interceptor in utils/axios.js
   *
   * @param {*} reqConfig See axios.request docs
   * @returns Promise
   */

  const navi = useNavigate();

  axios.defaults.headers.common['x-access-token'] = JSON.parse(localStorage.getItem('token'));
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';

  function makeRequest(reqConfig) {
    const config = {
      ...reqConfig,
      url: `${REACT_APP_PROXY}${reqConfig.url}`
    };

    // reset our state before dispatching request
    setLoading(true);
    setData(null);
    setError(null);

    return axios.request(config)
      .then((response) => {

        setData(response.data);
        setError(null);

        return response;
      })
      .catch((error) => {

        if (error?.response?.status === 401) {
          localStorage.removeItem('token');

          navi('/login');
        }

        setError(error);

        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return [makeRequest, data, loading, error];
}
