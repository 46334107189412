import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { ArrowDownward } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useRequest } from 'src/providers';

export const TotalCustomers = (props) => {

  const [getCustomerCount, customerCountData, isLoadingCustomerCount, errorGettingCustomerCount] = useRequest();

  const [state, setState] = useState({
    customerCount: 0,
    percent: 0,
    newCustomers: 0,
  });

  useEffect(() => {

    getCustomerCount({
      method: 'GET',
      url: `/v1/customer/count`
    }).then((response) => {

      if (response.data && response.data.data) {
        setState((prevState) => ({
          ...prevState,
          customerCount: response.data.data.totalCount || 0,
          newCustomers: response.data.data.count,
          percent: response.data.data.percentage || 0
        }));
      }
    });
  }, []);

  return (
    <Card {...props}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL CUSTOMERS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {state.customerCount}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
        >
          {state.percent > 0

            ?
            <>
              <ArrowUpwardIcon sx={{ color: green[900] }} />
              <Typography
                variant="body2"
                sx={{
                  color: green[900],
                  mr: 1
                }}
              >
                {state.percent}%
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                Since last month (+ {state.newCustomers} Customers)
              </Typography>
            </>

            :
            <>
              <ArrowDownward sx={{ color: red[900] }} />
              <Typography
                variant="body2"
                sx={{
                  color: red[900],
                  mr: 1
                }}
              >
                {state.percent}%
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                Since last month (+ {state.newCustomers} Customers)
              </Typography>
            </>
          }
        </Box>
      </CardContent>
    </Card>
  );
}
