import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { useRequest } from 'src/providers';
import getInitials from '../../utils/getInitials';
import { deepPurple } from '@material-ui/core/colors';
import { FileCopy, Print, Save, } from '@material-ui/icons';
import { Button, ButtonGroup, Tooltip, Chip } from '@mui/material';
import { useNavigate } from 'react-router';
import { Edit } from '@mui/icons-material';
import { getScreenSize } from 'src/utils/responsiveHook';
import { MobileInvoiceList } from './MobileInvoiceList';

export const InvoiceListResults = () => {

  const screenSize = getScreenSize();

  const navigator = useNavigate();

  const [state, setState] = useState({
    invoices: []
  });

  const [getInvoices, invoiceData, isLoadingInvoices, errorGettingInvoices] = useRequest();

  useEffect(() => {

    getInvoices({
      method: 'GET',
      url: '/v1/invoiceList'
    }).then((response) => {

      if (response.data && response.data.data) {

        setState((prevState) => ({
          ...prevState,
          invoices: response.data.data
        }));
      }
    });

  }, []);

  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = state.invoices.map((customer) => customer.uuid);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const actions = [
    { icon: <Edit />, name: 'Edit Invoice', onClick: (invoiceId) => navigator(`/app/invoice/${invoiceId}`) },
    { icon: <FileCopy />, name: 'View All Invoices', onClick: (invoiceId) => navigator(`invoice`) },
];

  const handleStatus = (status) => {

    switch (status) {
      case 'draft':
        return <Chip label={'Draft'} color={'error'} />
      case 'invoiced':
        return <Chip label={'Awaiting Payment'} color={'warn'} />
      case 'paid':
        return <Chip label={'Paid'} color={'success'} />
      default:
        return <Chip label={'Draft'} color={'error'} />
        break;
    }

  }

  return (
    <>
      {screenSize === 'desktop'

        ?
        <Card>
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Email
                    </TableCell>
                    <TableCell>
                      Contact Number
                    </TableCell>
                    <TableCell>
                      Invoice Date
                    </TableCell>
                    <TableCell>
                      Amount
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell align={'center'}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.invoices.slice(page * limit, page * limit + limit).map((invoice) => (
                    <TableRow
                      hover
                      key={invoice.id}
                      selected={selectedCustomerIds.indexOf(invoice.id) !== -1}>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}>
                          <Avatar
                            sx={{ bgcolor: deepPurple[500], mr: 2 }}>
                            {getInitials(invoice.customer.customerName)}
                          </Avatar>
                          <Typography
                            color="textPrimary"
                            variant="body1">
                            {invoice.customer.customerName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {invoice.customer.customerEmail}
                      </TableCell>
                      <TableCell>
                        {invoice.customer.customerContactNumber}
                      </TableCell>
                      <TableCell>
                        {moment(invoice.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        R{invoice.grandTotal}
                      </TableCell>
                      <TableCell>
                        {handleStatus(invoice.invoiceStatus)}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                              m: 0.05,
                            },
                          }}
                        >
                          <ButtonGroup size={'small'}>
                            {actions.map((action) => (
                              <Tooltip title={action.name}>
                                <Button color={action.color || 'primary'} key={action.name} onClick={() => action.onClick(invoice.uuid)}>
                                  {action.icon}

                                </Button>
                              </Tooltip>
                            ))}
                          </ButtonGroup>

                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={state.invoices.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>

        :

        <MobileInvoiceList
          data={invoiceData?.data}/>

      }

    </>
  );
};
